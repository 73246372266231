/** @jsx jsx */
import { jsx } from "theme-ui"
import { ReactNode } from "react"

type SiteMainProps = {
  children: ReactNode;
};

const SiteMain = ({ children }: SiteMainProps) => {
  return (
    <main
      sx={{
        my: 3,
        gridArea: "main",
        variant: "variants.main",
      }}
    >
      {children}
    </main>
  )
}

export default SiteMain
