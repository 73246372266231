/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => {
  return (
    <header
      sx={{
        width: "100vw",
        position: "relative",
        left: "calc(-50vw + 50%)",
        mt: -3,
        py: 4,
        px: 3,
        mb: 4,
        // backgroundColor: "header.background",
        backgroundImage: (theme) => 
          `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.header.background} 0%, ${theme.colors.accent} 90% )`,
        display: "grid",
        placeItems: "center",
      }}
    >

      <StaticImage
        src="../../content/assets/SHS_logo_horizontal.png"
        alt="Software Hardware Systems Logo and Title"
        loading="eager"
        placeholder="none"
        objectFit="fill"
      />

    </header>
  )
}

export default Hero
